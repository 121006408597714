body {
  font-family: 'Comic Sans MS', cursive, sans-serif;
  background-color: #0288d1;
  margin: 0;
  padding: 0;
  text-align: center;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #393d44;
  padding: 20px;
  color: white;
  position: relative;
}

header {
  background: #223c41;
  padding: 20px;
  color: white;
}

.logo-image {
  width: 250px;
  height: 250px;
}
.logo-image2 {
  width: 150px;
  height: 150px;
}

.game {
  border: 1px solid #ccc;
  padding: 16px;
  margin: 16px 0;
  text-align: center;
}
.game img {
  max-width: 50%;
  height: auto;
}

.content {
  padding: 20px;
  position: relative;
}

.input-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 20;
}


.input-address {
  background-color: rgba(255, 255, 0, 0.5); /* Fundo amarelo opaco suave */
  border: 1px solid #ccc; /* Borda cinza clara */
  padding: 10px; /* Espaçamento interno */
  border-radius: 5px; /* Cantos arredondados */
  width: 100%; /* Largura total do contêiner pai */
  box-sizing: border-box; /* Inclui padding e border no cálculo da largura total */
  font-size: 16px; /* Tamanho da fonte */
  outline: none; /* Remove o contorno ao focar */
}

.input-address:focus {
  border-color: #ffcc00; /* Cor da borda ao focar */
}


.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}

.animation-container {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.plane {
  position: absolute;
  top: 10%; /* Ajuste conforme necessário para posição vertical */
  width: 300px;
  height: 200px;
  animation: moveRight 6s linear infinite;
  z-index: 10;
}

@keyframes moveRight {
  from {
    left: -300px;
  }
  to {
    left: 100%;
  }
}


.airdrop {
  position: absolute;
  width: 100px;
  height: 60px;
  left: 30%;
  transform: translateX(-50%);
  animation: fall 7s linear infinite, grow 7s linear infinite;
  z-index: 25;
}

.clickable {
  cursor: pointer;
}

.clickable::after {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 2px solid transparent;
}

.clickable:hover::after {
  border-color: rgba(0, 0, 0, 0.1); /* Adiciona uma borda ao redor da imagem ao passar o mouse */
}

@keyframes moveRight {
  0% {
    left: -300px;
  }
  100% {
    left: 100%;
  }
}

@keyframes fall {
  0% {
    top: 0;
  }
  100% {
    top: calc(100vh - 60px);
    opacity: 0;
  }
}

@keyframes grow {
  0% {
    width: 70px;
    height: 40px;
  }
  100% {
    width: 200px;
    height: 120px;
  }
}

footer {
  background: #393d44;
  padding: 10px;
  color: white;
  
  width: 100%;
  bottom: 0;
}

.premio-container {
  padding: 20px;
  text-align: center;
  background-color: #f3e5f5;
  height: 100vh;
}

.prize-image {
  width: 300px;
  height: auto;
  margin: 20px auto;
}

.back-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.back-button:hover {
  background-color: #45a049;
}
